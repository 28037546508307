import React, { useState, useRef } from 'react';
import { useDeviceType } from 'src/hooks/useDeviceType';
import { Header } from '../Header';
import { HeaderDesktop } from '../HeaderDesktop';
import { BreakpointProvider } from 'src/hooks/useBreakpoint';
import { FooterDesktop } from '../FooterDesktop';
import { Footer } from '../Footer';
import {
  MainTextStyled,
  MainWrapper,
  JoinButton,
  DisabledJoinButton,
  InstructionBox,
  InstructionNumber,
  InstructionText,
  InstructionTitle,
  ReferralForm,
  FormInput,
  FormTitle,
  FormSubtitle,
  InfoSection,
  InfoGrid,
  InfoHeader,
  InfoTitle,
  InfoText,
  RankTable,
  TableHeader,
  TableRow,
  ShowButton,
  TitleContainer,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalText,
  ModalLinkContainer,
  ModalLink,
  ButtonTitleContainer,
  StoresLink,
  DiscordLink,
} from './styled';
import bgPhonePath from '../../assets/img/air-drop-page-phone.png';
import { ReactComponent as StarsIcon } from 'src/assets/icons/stars-air-drop.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/stars-air-drop.svg';
import { ReactComponent as DepositIcon } from 'src/assets/icons/deposit-icon.svg';
import { ReactComponent as ShieldIcon } from 'src/assets/icons/shield-icon.svg';
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy-icon.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';
import { Wrapper } from 'src/components/Container/styled';
import { useNavigate } from 'react-router-dom';

const phoneRegex = /^\+?[0-9]{7,14}$/;

const rewardData = [
  { rank: '1', reward: '1 winner / $1,000 ' },
  { rank: '2-3', reward: '2 winners / $400' },
  { rank: '4-5', reward: '2 winners / $250' },
  { rank: '6-10', reward: '5 winners / $150' },
  { rank: '11-20', reward: '10 winners / $50' },
  { rank: '21-50', reward: '30 winners / $25' },
  { rank: '51-100', reward: '50 winners / $14' },
];

const hashPhoneNumber = phone => {
  let hash = 0;
  for (let i = 0; i < phone.length; i++) {
    const char = phone.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash).toString(36);
};

const generateUTM = phone => {
  const hashedPhone = hashPhoneNumber(phone);
  const referralLink = `https://haqabi.onelink.me/zzKU?af_web_dp=https%3A%2F%2Fhaqabi.com%2F&af_xp=custom&pid=airdrop&c=${hashedPhone}&af_dp=haqabi%3A%2F%2F`;

  return referralLink;
};

export const AirDrop = () => {
  const { isMobile } = useDeviceType();
  const [phone, setPhone] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showFullList, setShowFullList] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  const formRef = useRef(null);

  const toggleShowFullList = () => {
    setShowFullList(!showFullList);
  };

  const handlePhoneInput = e => {
    const inputPhone = e.target.value;
    setPhone(inputPhone);
    setIsPhoneValid(phoneRegex.test(inputPhone));
  };

  const handleReferralSubmit = async e => {
    e.preventDefault();

    if (isPhoneValid) {
      const generatedLink = generateUTM(phone);
      setReferralLink(generatedLink);
      setShowModal(true);

      try {
        const response = await fetch(
          'https://api.dev.wallet.haqqex.tech/api/v1/landing-ref-link',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              phoneNumber: phone,
              refLink: generatedLink,
            }),
          },
        );

        if (!response.ok) {
          throw new Error('Failed to send data');
        }

        const data = await response.json();

        if (data.result) {
          console.log('Data successfully sent to the backend');
        } else {
          console.log('Failed to send data');
        }
      } catch (error) {
        console.error('Error sending data to the backend', error);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Wrapper $isMain>
      <div>{isMobile ? <Header /> : <HeaderDesktop />}</div>
      <BreakpointProvider>
        <MainWrapper>
          <ButtonTitleContainer>
            <TitleContainer>
              <MainTextStyled>September Airdrop</MainTextStyled>
              <MainTextStyled color="#C2EE4E">5000 USDT</MainTextStyled>
              <MainTextStyled style={{ fontSize: '4vw' }}>
                Get your reward on Oct 16, 2024
              </MainTextStyled>
            </TitleContainer>
            <JoinButton onClick={scrollToForm}>JOIN NOW</JoinButton>
          </ButtonTitleContainer>

          <div ref={formRef}>
            <InstructionTitle>Join by following these steps</InstructionTitle>
            <div>
              <InstructionBox>
                <InstructionNumber>1</InstructionNumber>
                <InstructionText>
                  Download Haqabi wallet from{' '}
                  <StoresLink href="https://haqabi.onelink.me/zzKU?af_web_dp=https%3A%2F%2Fhaqabi.com%2F&af_xp=custom&pid=landingairdrop&c=download&af_dp=https%3A%2F%2Fhaqabi.com%2F">
                    App Store
                  </StoresLink>{' '}
                  or{' '}
                  <StoresLink href="https://haqabi.onelink.me/zzKU?af_web_dp=https%3A%2F%2Fhaqabi.com%2F&af_xp=custom&pid=landingairdrop&c=download&af_dp=https%3A%2F%2Fhaqabi.com%2F">
                    Google Play
                  </StoresLink>{' '}
                  & register using your mobile number
                </InstructionText>
              </InstructionBox>

              <InstructionBox>
                <InstructionNumber>2</InstructionNumber>
                <InstructionText>
                  Increase your airdrop rank by inviting more friends through
                  your referral link
                </InstructionText>
              </InstructionBox>
            </div>
          </div>

          <ReferralForm bgImage={bgPhonePath} onSubmit={handleReferralSubmit}>
            <form
              style={{ display: 'flex', flexDirection: 'column', gap: '36px' }}>
              <div>
                <FormTitle>Get your referral link</FormTitle>
              </div>
              <FormInput
                type="text"
                value={phone}
                onChange={handlePhoneInput}
                placeholder="Enter phone number"
              />
              {isPhoneValid ? (
                <JoinButton type="submit" style={{ maxWidth: '705px' }}>
                  Get your referral link
                </JoinButton>
              ) : (
                <DisabledJoinButton
                  type="submit"
                  style={{ maxWidth: '705px' }}
                  disabled>
                  Get your referral link
                </DisabledJoinButton>
              )}
            </form>
          </ReferralForm>

          {showModal && (
            <ModalOverlay>
              <ModalContent>
                <CloseButton onClick={closeModal}>✕</CloseButton>
                <ModalText>
                  Share this referral link with your friends to download the app
                  and invite them to join the{' '}
                  <DiscordLink href="https://haqabi.com/airdrop">
                    Airdrop
                  </DiscordLink>
                </ModalText>
                <ModalLinkContainer isCopied={isCopied}>
                  <ModalLink>{referralLink}</ModalLink>
                  {isCopied ? (
                    <CheckIcon
                      style={{
                        transform: `scale(${isMobile ? '4' : '1.5'})`,
                        transformOrigin: 'center',
                      }}
                    />
                  ) : (
                    <CopyIcon
                      onClick={copyToClipboard}
                      style={{
                        transform: `scale(${isMobile ? '4' : '1.5'})`,
                        transformOrigin: 'center',
                        cursor: isMobile ? 'none' : 'pointer',
                      }}
                    />
                  )}
                </ModalLinkContainer>
                <JoinButton style={{ maxWidth: '100%' }} onClick={closeModal}>
                  OK
                </JoinButton>
              </ModalContent>
            </ModalOverlay>
          )}

          <InfoGrid>
            <InfoSection>
              <InfoHeader>
                <StarsIcon />
                <InfoTitle>Reward Pool - $5,000</InfoTitle>
              </InfoHeader>
              <InfoText>
                1st place: $1,000 <br /> 2nd & 3rd places: $400 each <br /> Top
                100 members receive $10 to $1,000 each.
              </InfoText>
            </InfoSection>
            <InfoSection>
              <InfoHeader>
                <CalendarIcon />
                <InfoTitle>Dates: September 13 - October 13, 2024</InfoTitle>
              </InfoHeader>
              <InfoText>
                Weekly leaderboards will be announced via community calls on{' '}
                <DiscordLink href="https://discord.com/invite/haqabihq">
                  Discord
                </DiscordLink>
                . <br /> Airdrop to your Haqabi wallet on October 16, 2024.
              </InfoText>
            </InfoSection>
            <InfoSection>
              <InfoHeader>
                <DepositIcon />
                <InfoTitle>Anti-Fraud Measures</InfoTitle>
              </InfoHeader>
              <InfoText>
                To prevent fraudulent activity, we will exclude bot accounts and
                give more weight to accounts that have shown financial
                transactions.
              </InfoText>
            </InfoSection>
            <InfoSection>
              <InfoHeader>
                <ShieldIcon />
                <InfoTitle>Eligibility & Fair Play</InfoTitle>
              </InfoHeader>
              <InfoText>
                Remember: cheaters will be excluded. Only one wallet per device.
              </InfoText>
            </InfoSection>
          </InfoGrid>

          <RankTable>
            <TableHeader>
              <p>{isMobile ? '№' : 'Rank'}</p>
              <p>Winners / Reward</p>
            </TableHeader>

            <div>
              {rewardData
                .slice(0, showFullList ? rewardData.length : 3)
                .map((item, index) => (
                  <TableRow key={index}>
                    <p>{item.rank}</p>
                    <p style={{ color: '#AADE16' }}>{item.reward}</p>
                  </TableRow>
                ))}
            </div>

            <ShowButton onClick={toggleShowFullList}>
              {showFullList ? 'Hide' : 'Show all'}
            </ShowButton>
          </RankTable>
          <InstructionBox>
            <InstructionText>
              All participants will receive 1,000 points in the Haqabi tree to
              use. The release Haqabi tree is coming soon.{' '}
              <DiscordLink href="https://t.me/haqabi">Follow us</DiscordLink> on
              social media for updates.
            </InstructionText>
          </InstructionBox>
        </MainWrapper>
        <div>{isMobile ? <Footer /> : <FooterDesktop />}</div>
      </BreakpointProvider>
    </Wrapper>
  );
};
