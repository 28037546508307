import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './App';
import PrivacyPolicy from './components/PrivacyPolicy/index';
import TermsAndUse from './components/TermsOfUse';
import TermsAndUseApp from './components/TermsOfUseApp';
import './index.scss';
import { CMS } from './components/CMS';
import Article from './components/CMS/views/article';
import { BreakpointProvider } from './hooks/useBreakpoint';
import { DeleteAccount } from './components/DeleteAccount';
import { DownloadApk } from './components/DownloadApk';
import { AirDrop } from './components/AirDrop';
import './integrations/firabse';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BreakpointProvider>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/terms-of-use" element={<TermsAndUse />} />
        <Route path="/terms-of-use-app" element={<TermsAndUseApp />} />
        <Route path="/privacy-policy-app" element={<PrivacyPolicy />} />
        <Route path={'/education'} element={<CMS />} />
        <Route path="/education/:articleId" element={<Article />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/download-apk" element={<DownloadApk />} />
        <Route path="/airdrop" element={<AirDrop />} />
      </Routes>
    </BrowserRouter>
  </BreakpointProvider>,
);
