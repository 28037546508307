import { ReactComponent as HaqabiIcon } from 'src/assets/icons/haqabi-icon.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/app-store.svg';
import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as DownloadApkIcon } from 'src/assets/icons/download-apk.svg';

import {
  ContentContactsStyled,
  ContentHaqabiStyled,
  ContentHaqabiTextStyled,
  ContentInfoStyled,
  ContentInfoTextStyled,
  ContentStyled,
  StoreItem,
} from './styled';
import { Container } from 'src/components/Container/styled';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderDesktop = ({ isMain }) => {
  const [elementId, setElementId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (elementId) {
      navigate('/', { state: { elementId } });
    }

    if (!isMain) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return () => setElementId('');
  }, [elementId, navigate]);

  return (
    <Container $isMain={isMain}>
      <ContentStyled>
        <ContentHaqabiStyled>
          <HaqabiIcon width={'3.194vw'} height={'3.681vw'} />
          <ContentHaqabiTextStyled>Haqabi</ContentHaqabiTextStyled>
        </ContentHaqabiStyled>
        <ContentInfoStyled>
          <ContentInfoTextStyled onClick={() => setElementId('Features')}>
            Features
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => setElementId('Comparison')}>
            Comparison
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => setElementId('Timeline')}>
            Timeline
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => setElementId('AboutUs')}>
            About us
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => setElementId('Education')}>
            Education
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => navigate('/airdrop')}>
            Airdrop
          </ContentInfoTextStyled>
        </ContentInfoStyled>
        <ContentContactsStyled>
          <StoreItem onClick={() => navigate('/download-apk')}>
            <DownloadApkText>APK</DownloadApkText>
            <DownloadApkIcon width={'1.667vw'} height={'1.667vw'} />
          </StoreItem>
          <StoreItem
            href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
            target="_blank">
            <PlayMarketIcon width={'1.667vw'} height={'1.667vw'} />
          </StoreItem>
          <StoreItem
            href="https://apps.apple.com/app/haqabi-wallet/id6477534740"
            target="_blank">
            <AppStoreIcon width={'1.667vw'} height={'1.667vw'} />
          </StoreItem>
        </ContentContactsStyled>
      </ContentStyled>
    </Container>
  );
};

const DownloadApkText = styled.p`
  font-size: 1.25vw;
  font-family: Inter, sans-serif;
  color: #c6cfd8;
`;
